import { mappers } from '@/views/app/orders/form/store';

const mapGetResponse = mappers.mapGetResponse;

const mapGetBreakdownResume = (orderDevices) => {
  const breakdownResume = {};
  for (let i = 0; i < orderDevices.length; i++) {
    const device = orderDevices[i];
    breakdownResume[device.id] = {};
    for (let j = 0; j < device.orderDeviceServices.length; j++) {
      const service = device.orderDeviceServices[j];
      breakdownResume[device.id][service.id] = {
        ...service,
        name: service.serviceCategory?.name || service.name || '- Servicio',
        type: 'Servicio',
        quantity: 1,
        total: service.price * 1,
      } ;
    }
    for (let k = 0; k < device.orderDeviceProducts.length; k++) {
      const product = device.orderDeviceProducts[k];
      breakdownResume[device.id][product.id] = {
        ...product,
        name: product.productCategory?.name || product.name || '- Producto',
        type: 'Producto',
        quantity: product.quantity,
        total: product.price * product.quantity,
      };
    }
  }
  return breakdownResume;
};

export default {
  mapGetResponse,
  mapGetBreakdownResume,
};
