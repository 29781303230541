<template>
  <app-layout>
    <div class="flex flex-col items-center pb-32">
      <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
        <div class="w-full mb-4 space-y-4">
          <div class="flex flex-col md:flex-row py-4">
            <div class="flex flex-row items-center">
              <sa-icon-button
                with-bg
                iconClass="bx bx-left-arrow-alt"
                @click="$router.push({ name: 'orders.read', params: { id: order.id } })" />
              <span class="mx-2 text-lg font-bold">
              Factura
            </span>
            </div>
            <div class="flex flex-row items-center ml-auto">
              <div class="whitespace-nowrap">
                <el-button @click="goPoliciesAndTerms" class="terms-button" :class="{ 'p-2' : isMobile }">
                  <span v-if="!isMobile">Configuración</span>
                  <i v-if="isMobile" class='text-md bx bx-cog'></i>
                </el-button>
                <el-dropdown v-if="id && !isMobile" split-button type="warning" class="mx-2" @click="print" @command="print">
                  <span>Imprimir</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="default">Carta</el-dropdown-item>
                    <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                    <el-dropdown-item disabled>Sticker</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button-group class="mr-2" style="margin-top: -6px;">
                  <span v-if="id && isMobile" class="el-button el-button--warning p-2" @click="print">
                    <i class="text-md bx bx-printer"></i>
                  </span>
                  <el-dropdown v-if="id && isMobile" trigger="click" @command="print">
                    <span class="el-button el-button--warning p-2">
                      <i class="text-md el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="default">Carta</el-dropdown-item>
                      <el-dropdown-item command="thermal">Térmico</el-dropdown-item>
                      <el-dropdown-item disabled>Sticker</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-button-group>
                <el-button @click="whatsapp" class="el-button--success" :class="{ 'p-2' : isMobile }" v-if="id">
                  <span v-if="!isMobile">WhatsApp</span>
                  <i v-if="isMobile" class='text-md bx bxl-whatsapp'></i>
                </el-button>
                <el-button @click="$router.push({ name: 'orders.index' })" class="el-button--primary" :class="{ 'p-2' : isMobile }">
                  <span v-if="!isMobile">Guardar</span>
                  <i v-if="isMobile" class='text-md bx bx-save'></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div id="print-area" class="w-full lg:w-1/2 items-center text-center m-auto space-y-4 relative">
          <div
            class="w-full items-center text-center py-2"
            v-for="device in order.orderDevices" :key="device.id">
            <div class="w-full flex flex-col border rounded-sm text-left p-2">
              <div :class="{ 'w-full flex justify-between': showLogo }">
                <div>
                  <div v-if="showLogo && logo" style="text-align: -webkit-center;">
                    <img :src="logo" alt="" :style="samiiInvoicesLogoSize" />
                  </div>
                  <div v-if="showLogo && !logo" style="text-align: -webkit-center;">
                    <img src="@/assets/img/mini-responsive-samii.png" alt="" width="70px">
                  </div>
                </div>
                <div :class="{ 'text-right': showLogo }">
                  <div v-if="false" class="w-full text-center mb-4" style="text-align: -webkit-center;">
                    <qr-code
                      :value="qrCodeValue"
                      size="150"
                      level="H" />
                  </div>
                  <div class="text-md">
                    <strong>Nombre Cliente: </strong>
                    {{ order.client.fullName }}
                  </div>
                  <div class="text-md">
                    <strong># Orden: </strong>
                    {{ order.reference }}
                  </div>
                  <div class="text-md">
                    <strong>Dispositivo: </strong>
                    {{ device.deviceModel.brand }} - {{ device.deviceModel.model }}
                  </div>
                </div>
              </div>
              <div class="text-md">
                <strong>Problema: </strong>
                {{ device.diagnosticNotes }}
              </div>
              <div class="text-md" v-if="showPasscode && device.deviceLockType === 1">
                <strong>Passcode: </strong>
                {{  device.passcode }}
              </div>
              <div class="text-md" v-if="showPasscode && device.deviceLockType === 2">
                <strong>Patron: </strong>
                {{ device.pattern }}
              </div>
              <div class="text-md" v-if="assigned">
                <strong>Asignado: </strong>
                {{ assigned }}
              </div>
              <div class="text-md" v-if="dueDate">
                <strong>Fecha Compromiso: </strong>
                {{ dueDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<style scoped>
#print > span, div, strong {
  color: #000000;
}
@media print {
  body *:not(#print-area):not(#print-area *) {
    visibility: hidden;
  }
  #print-area {
    position: absolute;
    visibility: visible;
    top: 0;
    left: 0;
    -webkit-print-color-adjust: exact;
  }
  #print-area * {
    overflow-x: hidden;
  }
}
.terms-button {
  @apply bg-blue-500 text-white;
}

.terms-button:hover {
  @apply opacity-80;
}
</style>
<script>
import BusinessGetters from '@/store/modules/business/getters';
import OrderService from '@/services/OrderService';
import mappers from './mappers';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import { tag } from '@/constants';
import responsiveSize from '@/mixins/responsiveSize';
import { PrintTypes } from '../../../constants';

export default {
  name: 'InvoiceSticker',
  mixins: [responsiveSize],
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
  },
  data() {
    return {
      id: null,
      order: {
        reference: null,
        client: {
          fullName: null,
          phone: null,
          email: null,
        },
        assigned: null,
        dueDate: null,
        orderDevices: [],
      },
      isMobile: false,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  },
  computed: {
    qrCodeValue() {
      return `${window.location.origin}/my-order-status/${this.id}`;
    },
    todayDate() {
      return this.$moment(new Date()).format('MM/DD/YYYY hh:mm:ss A');
    },
    dueDate() {
      if (this.order.dueDate)
        return this.$moment(this.order.dueDate).format('MM/DD/YYYY');
      return null;
    },
    assigned() {
      if (this.order.assignedToAccount)
        return this.order.assignedToAccount.fullname;
      return null;
    },
    showLogo() {
      return false;
      // TODO uncomment this lines when implements logo
      // const config = this.$store.state.invoices.config[PrintTypes.Sticker];
      // if (!config) {
      //   return true;
      // }
      // return config.showLogo;
    },
    showPasscode() {
      const config = this.$store.state.invoices.config[PrintTypes.Sticker];
      if (!config) {
        return true;
      }
      return config.showPasscode;
    },
    logo() {
      return BusinessGetters.logo();
    },
    samiiInvoicesLogoSize() {
      const config = this.$store.state.invoices.config[PrintTypes.Sticker];
      if (!config) {
        return {
          width: `100px !important`,
          height: `100px !important`,
        }
      }
      return {
        width: `${config.logoX}px !important`,
        height: `${config.logoY}px !important`,
      }
    },
  },
  methods: {
    whatsapp() {
      const countryCode = getCountryCode(
        this.$store.state.catalogs.flags,
        this.order.client.phoneCountryCodeId,
      );
      if (!countryCode) {
        this.$toastr.e('El Cliente debe tener registrado el Código de Area');
        return;
      }
      Whatsapp({
        code: countryCode,
        phone: this.order.client.phone,
        text: `Hola te envío el comprobante de tu orden.${tag.br}${tag.br}*${this.qrCodeValue}*`,
      });
      OrderService.addHistoryWhatsapp({
        orderId: this.order.id,
        message: `Hola te envío el comprobante de tu orden. *${this.qrCodeValue}*`,
      });
    },
    print(command) {
      if (typeof command === "string") {
        this.$router.push({ name: `invoices.${command}`, params: { id: this.id } });
      } else {
        window.print();
      }
    },
    async load() {
      const result = await OrderService.getById(this.id);
      this.order = mappers.mapGetResponse(result);
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    goPoliciesAndTerms() {
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if(isOwner || canViewAdministration){
        this.$router.push({ name: 'admin.index', params: { slot: 'policies-and-templates' } });
        return;
      }
      this.$swal('Aviso',
        'No tienes el permiso para realizar esta acción ',
        'error')
    },
  },
}
</script>
